<template>
  <document-view :header="currentDocument.name" :loading="currentDocumentLoading">
    <v-card flat>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col>
              <v-textarea
                  label="Комментарий"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  v-model="newComment"
              >
                <template v-slot:append-outer>
                  <v-btn
                      @click="commitNewComment"
                      :loading="newCommentSendLoading"
                  >
                    <v-icon :color="sendBtnColor">mdi-send</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row dense>
            <v-col cols="12" v-for="c in currentDocument.comments" :key="c.id">
              <v-card class="mx-auto">
                <v-card-title class="text-h6">
                  {{c.creatorName}}
                  <v-spacer></v-spacer>
                  <span class="body-2">{{ formatTime(c.created) }}</span>
                </v-card-title>
                <v-card-text
                    class="text-body-1"
                    style="text-align: left"
                    v-html="newLineReplace(c.content)" />

                <v-card-actions v-if="c.creatorID === user.personalEqmID">
                  <v-spacer></v-spacer>
                  <v-btn text disabled>
                    редактировать
                  </v-btn>
                  <v-btn text color="red" @click="deleteRemark(c.id)">
                    удалить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>
  </document-view>
</template>

<script>
import DocumentView from "../DocumentView/DocumentView";
import moment from "moment-timezone";
import {documentAddComment} from "../../api/document";
import {remarkDelete} from "../../api/other";
import {mapActions, mapState} from "vuex";

export default {
  name: "Comments",
  data: function () {
    return {
      newComment: '',
      newCommentSendLoading: false,
      sendBtnColor: '',
    }
  },
  computed: {
    ...mapState('document', ['currentDocument', 'currentDocumentLoading']),
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    ...mapActions('document', ['fetchCurrentDocument']),
    commitNewComment() {
      this.newCommentSendLoading = true
      documentAddComment(this.currentDocument.id, this.newComment, this.user.personalEqmID, this.$store.getters.getAuthToken)
      .then((r) => {
        if (r.success) {
          this.newComment = ''
          this.fetchCurrentDocument(this.$route.params.docID)
          this.sendBtnColor = ''
        } else {
          this.sendBtnColor = 'error'
          console.log('ошибка отправки комментария', r)
        }
      })
      .finally(() => {
        this.newCommentSendLoading = false
      })
    },
    deleteRemark(id) {
      remarkDelete(id, this.$store.getters.getAuthToken)
      .then((r) => {
        if (r.success) {
          this.fetchCurrentDocument(this.$route.params.docID)
        }
      })
    },
    newLineReplace(content) {
      return content.replace('\n', '<br>')
    },
    formatTime(t) {
      return moment(t).format('DD-MM-YYYY HH:mm:ss');
    }
  },
  components: {
    'DocumentView': DocumentView,
  }
}
</script>

<style scoped>

</style>